@import "../../styles/theme.scss";
.pageInformationWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.wrapper {
  display: grid;
  grid-template-columns: 112px 1fr;
  grid-template-rows: 1fr;
  column-gap: 2rem;
  align-items: start;
}
.pageInformationDatesWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reviewLink {
  display: flex;
  align-content: center;
  color: $color-primary;
  gap: 4px;
  padding: 0px !important;
}

.reviewLinkPadding {
  padding: 0.5rem 0rem;
}

.icon {
  height: 1rem !important;
  width: 1rem !important;
  min-height: 1rem !important;
  min-width: 1rem !important;
}
