@import "../../styles/theme.scss";

.accessibility-list {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 0;
  list-style-type: none;
  background: $blue-300;
  gap: 1rem;

  @include tablet-up {
    padding: 24px;
  }

  &__item {
    padding: 1rem;
    background: $color-white;

    @include tablet-up {
      padding: 2rem;
    }

    &__header {
      display: flex;
      align-items: center;
      padding: 0.5rem 0;
      border-bottom: 3px solid $blue-300;
      margin-bottom: 1.5rem;
      column-gap: 1rem;

      @include tablet-up {
        padding: 0.5rem 0 1rem;
      }

      &-icon {
        position: relative;
        width: 3rem;
        height: 3rem;
        flex: 0 0 3rem;

        @include tablet-up {
          width: 4rem;
          height: 4rem;
          flex: 0 0 4rem;
        }

        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          font-weight: 700;
          font-size: 1.125rem;

          @include tablet-up {
            font-size: 1.5rem;
          }
        }

        svg {
          width: 100%;
          height: 100%;

          polygon {
            fill: $blue-300;
          }
        }
      }

      &-title {
        flex: 1;
        font-size: 1.125rem;
        line-height: 1.75rem;
        font-weight: bold;

        @include tablet-up {
          font-size: 1.5rem;
        }
      }
    }

    &__content {
      &-section {
        font-size: 1.125rem;
        line-height: 1.75rem;
        margin-bottom: 1.5rem;

        &-heading {
          font-weight: bold;
          font-size: 1.25rem;
        }

        &-paragraph {
          font-size: 1.125rem;
          line-height: 1.75rem;
          margin: 0.5rem 0;
        }

        &-list {
          padding-left: 20px;

          font-size: 1.125rem;
          line-height: 1.75rem;

          @include tablet-up {
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: revert;
          }

          & > li {
            margin-bottom: 0.5rem;
          }
        }

        &-link {
          color: $blue-700;
          text-decoration: underline;
          font-size: 1.125rem;
          line-height: 1.75rem;
        }
      }
    }

    &__accordion {
      margin-bottom: 1.5rem;
    }

    &__tags {
      margin-bottom: 2rem;

      & > p {
        margin-bottom: 0;
      }

      &-list {
        display: flex;
        column-gap: 1rem;

        p {
          margin: 0.5rem 0 0;
          padding: 0.5rem 1rem;
          background: $blue-300;
          color: $blue-700;
          font-size: 1.25rem;
          line-height: 1.75rem;
          font-weight: bold;
        }
      }
    }
  }
}
